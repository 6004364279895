import moment from 'moment'
import {
  NOTIFICATION_STATUS_ERROR,
  NOTIFICATION_STATUS_WARNING,
} from './constants'

const lessThan2HoursApart = (notificationA, notificationB) =>
  moment(notificationA.created).diff(moment(notificationB.created), 'hours') >=
  2

export const combineSimilar = (notifications) => {
  var combined = []
  notifications.forEach((notification) => {
    var similar = combined.find(
      (notificationToMatch) =>
        (notificationToMatch.groupId &&
          notificationToMatch.groupId === notification.groupId) ||
        (!notification.groupId &&
          notificationToMatch.title === notification.title &&
          notificationToMatch.status === notification.status &&
          notificationToMatch.link === notification.link)
    )
    if (!similar || lessThan2HoursApart(similar, notification)) {
      combined.push({
        ...notification,
        ids: [notification.id],
        descriptions: [notification.description],
      })
    } else {
      if (notification.status === NOTIFICATION_STATUS_ERROR) {
        similar.status = notification.status
      }
      if (
        notification.status === NOTIFICATION_STATUS_WARNING &&
        similar.status !== NOTIFICATION_STATUS_ERROR
      ) {
        similar.status = notification.status
      }
      similar.descriptions = [...similar.descriptions, notification.description]
      similar.ids = [...similar.ids, notification.id]
    }
  })

  return combined
}
